@import "../../all-modules.global";

.right-menu {
  display: flex;
  align-items: center;
  gap: 16px;
  @include font-size(m);
  min-height: 48px;

  &__menu {
    display: flex;
    align-items: center;
  }
}

@media (max-width: $header-menu-width) {
  .right-menu {
    display: none;
  }
}